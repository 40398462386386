import React from "react"
import PropTypes from "prop-types"

const BackgroundItemSvg = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="480"
      height="872"
      viewBox="0 0 480 872"
      className={'background-item-svg'}
    >
      <g transform="translate(-1440 -1408)">
        <g transform="translate(2338 1659) rotate(90)">
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(239 614)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(239 418)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(239 712)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(239 516)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(337 614)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(337 418)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(337 712)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(337 810)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(337 516)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(435 614)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(435 418)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(435 712)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(435 516)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="44"
            transform="translate(435 810)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="44"
            transform="translate(533 810)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="44"
            transform="translate(533 516)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(533 614)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(533 712)"
            fill="#e6e6e6"
            opacity="0.519"
          />
        </g>
        <g transform="translate(2159 2306) rotate(180)">
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(239 614)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(239 418)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(239 712)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(239 516)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(337 614)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(337 418)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(337 712)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(337 810)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(337 516)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(435 614)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(435 418)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(435 712)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(435 516)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="44"
            transform="translate(435 810)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="44"
            transform="translate(533 810)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="44"
            transform="translate(533 516)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(533 614)"
            fill="#e6e6e6"
            opacity="0.519"
          />
          <rect
            width="88"
            height="88"
            rx="23"
            transform="translate(533 712)"
            fill="#e6e6e6"
            opacity="0.519"
          />
        </g>
      </g>
    </svg>
  )
}

BackgroundItemSvg.propTypes = {}

export default BackgroundItemSvg
