import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import useWindowSize from "../../hooks/useWindowSize"
import { motion, useAnimation } from "framer-motion"
import { AnimatePresence } from "framer-motion"
import { useListLogos } from "../../hooks/list-logos"
import Img from "gatsby-image"

const variants = {
  visible: {
    opacity: 1,
    rotate: 0,
    scale:1,
    duration: 2,
  },
  hidden: {
    opacity: 0,
    rotate: -760,
    scale: 0.1,
  }
}


const Logo = props => {
const controls = useAnimation()
  return(
    <AnimatePresence>
      <motion.div initial={"hidden"}
                  exit={"hidden"}
                  animate={"visible"}
                  variants={variants}
                  key={props.url}
      >
        <Img fixed={props.url} imgStyle={{width: "auto", height: "100%", objectFit: "none"}}/>
      </motion.div>
    </AnimatePresence>
  )
}

const BackgroundLogo = props => {
  const logosimages = useListLogos()
  const [counter, setCounter] = useState(logosimages.length)
  const [logos, setLogos] = useState([])
  const [itemNbr, setItemNbr] = useState(0)
  const [activeLogo, setActiveLogo] = useState()
  const logoContainer = useRef()

  const size = useWindowSize()

  const createLogosArray = () => {
    console.log(logosimages[1].node)

    const width = logoContainer.current.getBoundingClientRect().width
    const height = logoContainer.current.getBoundingClientRect().height

    console.log(height)

    const grid = Math.trunc(height / 115) * Math.trunc(width / 115)

    console.log(grid)

    setItemNbr(grid)

    let arr = []

    for(let i = 0; i < grid; i++){
      arr.push({
        id: i,
        url: '',
      })
    }
    setLogos(arr)
  }

  const rngLogo = () => {
    const id = Math.floor(Math.random() * itemNbr)
    return id
  }

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 500);

    const rngId = rngLogo()

    let array2 = logos.map(a => {
      let returnValue = {...a};

      if (a.id == rngId && returnValue.url == '') {
        returnValue.url = logosimages[counter].node.childImageSharp !== null ? logosimages[counter].node.childImageSharp.fixed : null
      }

      return returnValue
    })
    setLogos(array2)

  }, [counter])

  useEffect(() => {
    createLogosArray()
  }, [])

  return (
    <>
    <div ref={logoContainer} className={'home-bg-logos'}>
      {
        logos.map(logo => {
          return <div className="home-bg-logos__item" key={logo.id} data-id={logo.id}>
              {
                (logo.url !== '' && logo.url !== null) &&
                  <Logo url={logo.url} />
              }
          </div>
        })
      }
    </div>
    </>
  )
}

BackgroundLogo.propTypes = {

}

export default BackgroundLogo
