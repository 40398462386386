import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import HomeIntro from "../components/home/home-intro"
import MoreToCome from "../components/home/more-to-come"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Welcome to the SESF Members !" />
    <MoreToCome />
    <div id="intros">
      {data.allMarkdownRemark.edges.map(({ node }, index) => (
        <HomeIntro
          interview={node}
          odd={index % 2}
          key={index}
        />
      ))}
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query AllPost {
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, filter: {fileAbsolutePath: {regex: "/interview/"}}) {
      edges {
        node {
          frontmatter {
            logo {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            membershiptype
            title
            introduction
            featured_quote
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
