import { useStaticQuery, graphql } from "gatsby"

export const useListLogos = () => {
  const { allFile } = useStaticQuery(
    graphql`
        query listLogos {
            allFile(filter: {sourceInstanceName: {eq: "logos"}}) {
                edges {
                    node {
                        childImageSharp {
                            fixed(width: 75) {
                                ...GatsbyImageSharpFixed_noBase64
                            }
                        }
                    }
                }
            }
        }
    `
  )
  return allFile.edges
}
