import React, { useState } from "react"
import PropTypes from "prop-types"
import TransitionLink from "gatsby-plugin-transition-link"
import Image from "gatsby-image"
import { motion } from "framer-motion"
import BackgroundItemSvg from "./background-item"
import { InView } from "react-intersection-observer"
import AnimLink from "../../utils/anim-link"

const MembershipType = props => {

  const titleVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
    },
  }

  return <motion.span className="home-intro__membership-tag" variants={titleVariants}>{props.type}</motion.span>
}

const MemberCard = props => {
  const [hover, setHover] = useState(false)
  const title = props.itw.frontmatter.title
  const membershipType = props.itw.frontmatter.membershiptype
  const introduction = props.itw.frontmatter.introduction
  const featuredQuote = props.itw.frontmatter.featured_quote
  const slug = props.itw.fields.slug
  const img =
    props.itw.frontmatter.logo !== null
      ? props.itw.frontmatter.logo.childImageSharp.fluid
      : null

  const variants = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
    transition: {
      delay: 0.7,
      duration: 1,
      staggerChildren: 1.3,
    }
  }

  const titleVariants = {
    hidden: {
      opacity: 0,
      y: 120,
    },
    show: {
      opacity: 1,
      y: 0,
    },
  }

  const variantsMemberCards = {
    hidden: {
      opacity: 0,
      y: 130
    },
    show: {
      opacity: 1,
      y: 0
    },
  }

  const handleHover = state => {
    setHover(state)
  }

  return(
    <motion.div
      className={props.odd ? "home-intro odd" : "home-intro even"}
      data-title={title}
      initial={"hidden"}
      animate={props.inView ? "show" : "hidden"}
      variants={variants}
    >
      <BackgroundItemSvg />
      <div className="home-intro__identite">
        <div className="home-intro__media">
          {img && <Image fluid={img} />}
        </div>
        <div className="home-intro__description">
          <motion.h2
            className={"home-intro__member"}
            variants={titleVariants}
          >
            {title}
          </motion.h2>
          {membershipType.map((type, index) => {
            return <MembershipType type={type} key={index} />
          })}
        </div>
      </div>
      <motion.blockquote className={"home-intro__featured-quote"} variants={titleVariants}>
        {featuredQuote}
      </motion.blockquote>
      <motion.p className={"home-intro__introduction"} variants={titleVariants}>{introduction}</motion.p>
      <motion.div variants={titleVariants}>
        <AnimLink
          className={"btn btn-primary"}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          to={slug}
        >
          Read interview
        </AnimLink>
      </motion.div>
    </motion.div>
  )
}

const HomeIntro = props => {
  const [inView, setInView] = useState(false)
  const { interview } = props

  return (
    <InView triggerOnce={true} rootMargin={"0px 0px -200px 0px"} onChange={setInView}>
      {({ ref, inView }) => (
        <div ref={ref}>
          <MemberCard odd={props.odd} inView={inView} itw={interview}/>
        </div>
      )}
    </InView>
  )
}

HomeIntro.propTypes = {
  title: PropTypes.string,
  location: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  excerpt: PropTypes.string,
}

export default HomeIntro
