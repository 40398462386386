import React, { useEffect } from "react"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import BackgroundLogo from "./background-logo"

const MoreToCome = props => {
  const scrollToNext = () => {
    const offsetTop = document.querySelector("#intros").offsetTop
    window.scroll({
      top: offsetTop,
      behavior: "smooth",
    })
  }

  const variants = {
    visible: custom => ({
      scale: 1,
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
    }),
  }

  const variantsHeart = {
    beat: {
      scale: [1, 1.1, 1, 1.1, 1, 1, 1.1, 1, 1, 1.1, 1, 1.1, 1, 1, 1.1, 1],
      color: [
        "#2c2c2c",
        "#CB0A00",
        "#2c2c2c",
        "#CB0A00",
        "#2c2c2c",
        "#2c2c2c",
        "#CB0A00",
        "#2c2c2c",
        "#2c2c2c",
        "#CB0A00",
        "#2c2c2c",
        "#CB0A00",
        "#2c2c2c",
        "#2c2c2c",
        "#CB0A00",
        "#2c2c2c",
      ],
    },
  }

  return (
    <div className="home-jumbotron-wrapper">
      <BackgroundLogo />
      <div className={"home-jumbotron"}>
        <motion.h1
          custom={0}
          initial={{ opacity: 0, scale: 1.2, y: -10 }}
          animate={"visible"}
          variants={variants}
        >
          SESF MEMBERS
        </motion.h1>
        <motion.h2
          custom={1}
          initial={{ opacity: 0, scale: 1.2, y: -10 }}
          animate={"visible"}
          variants={variants}
          className={"h2"}
        >
          The Beating{" "}
          <motion.span
            initial={{ scale: 1 }}
            animate={"beat"}
            transition={{ delay: 1, duration: 2 }}
            variants={variantsHeart}
            className={"heart"}
            data-text={"Heart"}
          >
            Heart
          </motion.span>{" "}
          of Our Federation
        </motion.h2>
        <motion.p
          custom={2}
          initial={{ opacity: 0, scale: 1.2 }}
          animate={"visible"}
          variants={variants}
        >
          Learn more about our members, their projects and what drives them to
          follow their dreams! Here, you will find some interviews with the
          greatest esports organisations in Switzerland and you will have the
          opportunity to learn more about the Swiss esports scene.
        </motion.p>
        <motion.button
          custom={3}
          initial={{ opacity: 0, scale: 1.2 }}
          animate={"visible"}
          variants={variants}
          className={"btn btn-primary"}
          onClick={scrollToNext}
        >
          Discover
        </motion.button>
      </div>
    </div>
  )
}

MoreToCome.propTypes = {}

MoreToCome.defaultProps = {}

export default MoreToCome
